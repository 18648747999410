import { combineReducers } from 'redux';
import NotificationsReducer from './notificationsReducer';
import CountsReducer from './countsReducer';
import UserReducer from './userReducer';
import WholesaleProductsReducer from './wholesaleProductsReducer';
import CommunityNotificationReducer from './communityNotificationReducer';

export default combineReducers({
  notifications: NotificationsReducer,
  counts: CountsReducer,
  currentUser: UserReducer,
  wholesaleProducts: WholesaleProductsReducer,
  communityNotifications: CommunityNotificationReducer,
});
