import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DeleteIcon from '@material-ui/icons/Delete';

import './groups.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Groups({ history }) {
  const communityNotifications = useSelector(state => state.communityNotifications);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({
    live: false,
    name: '',
    description: '',
    imageUrl: '',
  });
  const [newGroupOpen, setNewGroupOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios(`${config.gateway}/community-service/api/v1/groups`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setGroups(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching groups, please try again.');
    }
  };

  const saveNewGroup = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios(`${config.gateway}/community-service/api/v1/groups`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: newGroup,
      });

      setLoading(false);
      setNewGroupOpen(false);
      history.push(`/community/groups/${response.data.id}`);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this group, please try again.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios(`${config.gateway}/community-service/api/v1/groups/${groups[deleteIndex].id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const updatedGroups = [ ...groups ];
      updatedGroups.splice(deleteIndex, 1);

      setGroups(updatedGroups);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this group, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Groups">
      {renderLoading()}
      <Card>
        <Toolbar className="toolbar">
          <Typography variant="h6">
            Groups
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              setNewGroup({
                live: false,
                name: '',
                description: '',
                imageUrl: '',
              });
              setNewGroupOpen(true);
            }}
          >
            Add Group
          </Button>
        </Toolbar>

        <List>
          {groups.map((g, i) => {
            let notifications = 0;

            const posts = communityNotifications[g.id] || [];

            posts.forEach(post => {
              notifications++;
              notifications += post.commentsCount;
            });

            return (
              <div key={g.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <>{g.name} </>
                        {!notifications ? null :
                          <span className="notification-count-container">
                            {notifications}
                          </span>
                        }
                      </>
                    }
                    secondary={`ID: ${g.id}`}
                  />

                  <Link edge="start" to={`/community/groups/${g.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>

                  <Link edge="start" to={`/community/groups/${g.id}/posts`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <DynamicFeedIcon />
                    </IconButton>
                  </Link>

                  <IconButton
                    color="inherit"
                    aria-label="Edit"
                    onClick={() => setDeleteIndex(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>

        <Divider/>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={newGroupOpen} onClose={() => setNewGroupOpen(false)} TransitionComponent={Transition}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newGroup.name}
            onChange={(e) => {
              setNewGroup({
                ...newGroup,
                name: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={newGroup.description}
            onChange={(e) => {
              setNewGroup({
                ...newGroup,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Image URL"
            value={newGroup.imageUrl}
            onChange={(e) => {
              setNewGroup({
                ...newGroup,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => saveNewGroup()}
            color="primary"
            disabled={!newGroup.name || !newGroup.description || !newGroup.imageUrl}
          >
            Save
          </Button>
          <Button variant="contained" onClick={() => setNewGroupOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Group?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this group?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => confirmDelete()} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Groups;
