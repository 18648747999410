import {
  SET_COMMUNITY_NOTIFICATIONS,
} from './types';

export const setCommunityNotifications = (data) => {
  return {
    type: SET_COMMUNITY_NOTIFICATIONS,
    payload: data,
  };
};
