import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import Behavior from './behavior';
import NewPup from './newPup';
import Course from './course';
import ProductList from './productList';
import CheckoutProducts from './checkoutProducts';
import OneClickUpsell from './oneClickUpsell';
import Quizzes from './quizzes';
import FileManager from './fileManager/fileManager';
import AcademyContent from './academyContent/academyContent';
import Community from './community/community';
import Reports from './reports/reports';
import BlogContent from './blogContent/blogContent';
import CustomerService from './customerService/customerService';
import WebsiteContent from './websiteContent/websiteContent';
import MarketingTools from './marketingTools/marketingTools';
import Rewards from './rewards/rewards';
import Services from './services/services';
import Partners from './partners/partners';
import RetailMicrosite from './retailMicrosite/retailMicrosite';
import Shop from './shop/shop';
import Wholesale from './wholesale/wholesale';
import UserManager from './userManager/userManager';
import Welcome from './welcome';
import { setNotifications, setCurrentUserAccess, setCommunityNotifications } from '../actions';
import { config } from '../config';

class Authed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      showAcademyTabs: false,
      showBlogContentTabs: false,
      showCustomerServiceTabs: false,
      showCommunityTabs: false,
      showRewardsTabs: false,
      showServicesTabs: false,
      showRetailMicrositeTabs: false,
      showPartnersTabs: false,
      showWebsiteContentTabs: false,
      showMarketingToolsTabs: false,
      showShopTabs: false,
      showWholesaleTabs: false,
      showUserTabs: false,
      showReportsTabs: false,
      access: [],
      isSuperAdmin: false,
      blogCommentsUnsubscribe: null,
      communityNotificationsCount: 0,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes('/blog-content')) {
      this.setState({ showBlogContentTabs: true });
    }

    if (window.location.pathname.includes('/customer-service')) {
      this.setState({ showCustomerServiceTabs: true });
    }

    if (window.location.pathname.includes('/community')) {
      this.setState({ showCommunityTabs: true });
    }

    if (window.location.pathname.includes('/rewards')) {
      this.setState({ showRewardsTabs: true });
    }

    if (window.location.pathname.includes('/services')) {
      this.setState({ showServicesTabs: true });
    }

    if (window.location.pathname.includes('/retail-microsite')) {
      this.setState({ showRetailMicrositeTabs: true });
    }

    if (window.location.pathname.includes('/partners')) {
      this.setState({ showPartnersTabs: true });
    }

    if (window.location.pathname.includes('/website-content')) {
      this.setState({ showWebsiteContentTabs: true });
    }

    if (window.location.pathname.includes('/marketing-tools')) {
      this.setState({ showMarketingToolsTabs: true });
    }

    if (window.location.pathname.includes('/shop')) {
      this.setState({ showShopTabs: true });
    }

    if (window.location.pathname.includes('/wholesale')) {
      this.setState({ showWholesaleTabs: true });
    }

    if (window.location.pathname.includes('/manage-users')) {
      this.setState({ showUserTabs: true });
    }

    if (window.location.pathname.includes('/academy-content')) {
      this.setState({ showAcademyTabs: true });
    }

    if (window.location.pathname.includes('/reports')) {
      this.setState({ showReportsTabs: true });
    }

    this.fetchRoles();
    this.fetchNotifications();
    this.fetchCommunityNotifications();
  }

  componentWillUnmount() {
    if (this.state.blogCommentsUnsubscribe) {
      this.state.blogCommentsUnsubscribe();
    }

    if (this.state.failedOrdoroOrdersUnsubscribe) {
      this.state.failedOrdoroOrdersUnsubscribe();
    }
  }

  async fetchNotifications() {
    const blogCommentsUnsubscribe = firebase
      .firestore()
      .collection('blog-comments')
      .doc('data')
      .onSnapshot((blogCommentDataSnapshot) => {
        const data = blogCommentDataSnapshot.data();

        this.props.setNotifications({
          blogNotificationCount: data.pendingComments.length,
          pendingBlogNotificationCount: data.pendingComments.length,
        });
      });

    const failedOrdoroOrdersUnsubscribe = firebase
      .firestore()
      .collection('failed-ordoro-orders')
      .where('resolved', '==', false)
      .onSnapshot((failedOrdoroOrdersSnapshot) => {
        this.props.setNotifications({
          failedOrdoroOrderCount: failedOrdoroOrdersSnapshot.docs.length,
        });
      });

    this.setState({ blogCommentsUnsubscribe, failedOrdoroOrdersUnsubscribe });
  }

  async fetchCommunityNotifications() {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(`${config.gateway}/community-service/api/v1/admin/unread`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      this.props.setCommunityNotifications(response.data || {});
    } catch (e) {
      console.log('Error fetching community notifications: ', e);
    }
  }

  async fetchRoles() {
    const db = firebase.firestore();
    try {
      const userId = firebase.auth().currentUser.uid;
      const userSnapshot = await db.collection('users').doc(userId).get();
      const rolesSnapshot = await db
        .collection('user-roles')
        .doc('roles')
        .get();
      const user = userSnapshot.data();
      const userRoles = (user && user.roles) || [];
      const groups = rolesSnapshot.data().groups;
      const groupsMap = {};
      const access = [];

      groups.forEach((group) => {
        groupsMap[group.value] = group.hasAccessTo;
      });

      userRoles.forEach((role) => {
        if (groupsMap[role]) {
          groupsMap[role].forEach((accessTier) => {
            if (!access.includes(accessTier)) {
              access.push(accessTier);
            }
          });
        }
      });

      this.props.setCurrentUserAccess(access);
      this.setState({ access, isSuperAdmin: access.includes('super-admin') });
    } catch (e) {
      console.log('error fetching roles', e);
    }
  }

  renderCommunityNotifications() {
    let count = 0;

    const groups = this.props.communityNotifications || {};

    for (let groupId in groups) {
      const posts = groups[groupId];

      count += posts.length;

      posts.forEach(post => {
        count += post.commentsCount;
      });
    }

    if (!count) {
      return;
    }

    return (
      <span className="notification-count-container">
        {count}
      </span>
    );
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  drawer() {
    return (
      <div className="navigation">
        {!(
          this.state.isSuperAdmin || this.state.access.includes('content')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <Link
                style={{ textDecoration: 'none', color: '#000' }}
                to="/file-manager"
              >
                <ListItem
                  button
                  onClick={() => {
                    this.setState({ mobileOpen: false });
                  }}
                  selected={window.location.pathname === '/file-manager'}
                >
                  <ListItemText primary={'File Manager'} />
                </ListItem>
              </Link>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('shop-data') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('content')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showAcademyTabs: !this.state.showAcademyTabs,
                  });
                }}
                selected={window.location.pathname.includes('/academy-content')}
              >
                <ListItemText primary={'Academy'} />
                {this.state.showAcademyTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showAcademyTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/free-courses/"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/free-courses/',
                          )}
                        >
                          <ListItemText primary={'Free Courses'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/free-courses-v2"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/free-courses-v2',
                          )}
                        >
                          <ListItemText primary={'Free Courses V2'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/completion-ads"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/completion-ads',
                          )}
                        >
                          <ListItemText primary={'Completion Ads'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/member-content/"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/member-content/',
                          )}
                        >
                          <ListItemText primary={'Academy+'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/member-content-v2"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/member-content-v2',
                          )}
                        >
                          <ListItemText primary={'Academy+ V2'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/academy-plus-course-landing-pages"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/academy-plus-course-landing-pages',
                          )}
                        >
                          <ListItemText primary={'Academy+ Landing'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/academy-bonus"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/academy-bonus',
                          )}
                        >
                          <ListItemText primary={'Academy Bonus'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/featured-content"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/featured-content',
                          )}
                        >
                          <ListItemText primary={'Featured Content'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/visual-search"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/visual-search',
                          )}
                        >
                          <ListItemText primary={'Visual Search'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/weekly-challenges"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/weekly-challenges',
                          )}
                        >
                          <ListItemText primary={'Monthly Challenges'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/app-welcome-messages"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/app-welcome-messages',
                          )}
                        >
                          <ListItemText primary={'Welcome Messages'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                  {!this.state.isSuperAdmin ? null : (
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/academy-content/admin-whitelist"
                    >
                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ mobileOpen: false });
                        }}
                        selected={window.location.pathname.includes(
                          '/academy-content/admin-whitelist',
                        )}
                      >
                        <ListItemText primary={'Admin Whitelist'} />
                      </ListItem>
                    </Link>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('shop-data') ||
                    this.state.access.includes('reports')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/training-tools"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/training-tools',
                          )}
                        >
                          <ListItemText primary={'Training Tools'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/academy-content/orders"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          selected={window.location.pathname.includes(
                            '/academy-content/orders',
                          )}
                        >
                          <ListItemText primary={'App Orders'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin || this.state.access.includes('content')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showCommunityTabs: !this.state.showCommunityTabs,
                  });
                }}
                selected={window.location.pathname.includes('/community')}
              >
                <ListItemText
                  primary={
                    <span>
                      Community{' '}
                      {this.renderCommunityNotifications()}
                    </span>
                  }
                />
                {this.state.showCommunityTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showCommunityTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/community/groups"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/community/groups'
                          }
                        >
                          <ListItemText
                            primary={
                              <span>
                                Groups{' '}
                                {this.renderCommunityNotifications()}
                              </span>
                            }
                          />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/community/posts/flagged"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/community/posts/flagged'
                          }
                        >
                          <ListItemText primary={'Post Flags'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/community/comments/flagged"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/community/comments/flagged'
                          }
                        >
                          <ListItemText primary={'Comment Flags'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin || this.state.access.includes('reports')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showReportsTabs: !this.state.showReportsTabs,
                  });
                }}
                selected={window.location.pathname.includes('/reports')}
              >
                <ListItemText primary={'Reports'} />
                {this.state.showReportsTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showReportsTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/orders"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={window.location.pathname === '/reports/orders'}
                    >
                      <ListItemText primary={'Orders'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/orders-today"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/reports/orders-today'
                      }
                    >
                      <ListItemText primary={'Orders Today'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/wholesale"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/reports/wholesale'
                      }
                    >
                      <ListItemText primary={'Wholesale'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/new-subscriptions"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/reports/new-subscriptions'
                      }
                    >
                      <ListItemText primary={'New Subs'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/renewed-subscriptions"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/reports/renewed-subscriptions'
                      }
                    >
                      <ListItemText primary={'Renewed Subs'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/canceled-subscriptions"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/reports/canceled-subscriptions'
                      }
                    >
                      <ListItemText primary={'Canceled Subs'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/subscription-forecast"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/reports/subscription-forecast'
                      }
                    >
                      <ListItemText primary={'Subs Forecast'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/subscription-retention"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/reports/subscription-retention'
                      }
                    >
                      <ListItemText primary={'Subs Retention'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/chewy"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={window.location.pathname === '/reports/chewy'}
                    >
                      <ListItemText primary={'Chewy'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/amazon"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={window.location.pathname === '/reports/amazon'}
                    >
                      <ListItemText primary={'Amazon'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/reports/customer-ltv"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/reports/customer-ltv'
                      }
                    >
                      <ListItemText primary={'Customer LTV'} />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('users') ||
          this.state.access.includes('reports')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({ showUserTabs: !this.state.showUserTabs });
                }}
                selected={window.location.pathname.includes('/manage-users')}
              >
                <ListItemText primary={'Manage Users'} />
                {this.state.showUserTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showUserTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('reports')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/manage-users/dashboard"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/manage-users/dashboard'
                          }
                        >
                          <ListItemText primary={'Dashboard'} />
                        </ListItem>
                      </Link>
                      {!this.state.isSuperAdmin ? null : (
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/manage-users/roles-and-permissions"
                        >
                          <ListItem
                            button
                            onClick={() => {
                              this.setState({ mobileOpen: false });
                            }}
                            style={{ paddingLeft: 25, marginTop: 1 }}
                            selected={window.location.pathname.includes(
                              '/manage-users/roles-and-permissions',
                            )}
                          >
                            <ListItemText primary={'Roles & Permissions'} />
                          </ListItem>
                        </Link>
                      )}
                    </>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('users')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/manage-users/users"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/manage-users/users',
                          )}
                        >
                          <ListItemText primary={'Users'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/manage-users/action-verification"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/manage-users/action-verification',
                          )}
                        >
                          <ListItemText primary={'Action Verification'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('order-data') ||
          this.state.access.includes('product-reviews') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('content') ||
          this.state.access.includes('financial') ||
          this.state.access.includes('shop-data') ||
          this.state.access.includes('shop-dashboard')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({ showShopTabs: !this.state.showShopTabs });
                }}
                selected={window.location.pathname.includes('/shop')}
              >
                <ListItemText primary={'Shop'} />
                {this.state.showShopTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showShopTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('financial')
                  ) ? null : (
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/shop/settings"
                    >
                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ mobileOpen: false });
                        }}
                        style={{ paddingLeft: 25, marginTop: 1 }}
                        selected={window.location.pathname === '/shop/settings'}
                      >
                        <ListItemText primary={'Settings'} />
                      </ListItem>
                    </Link>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('order-data')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/insurance-claims"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/shop/insurance-claims',
                          )}
                        >
                          <ListItemText primary={'Insurance Claims'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/orders"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/shop/orders',
                          )}
                        >
                          <ListItemText primary={'Orders'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/subscriptions"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/shop/subscriptions',
                          )}
                        >
                          <ListItemText primary={'Subscriptions'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('shop-data')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/subscription-boxes"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/shop/subscription-boxes',
                          )}
                        >
                          <ListItemText primary={'Subscription Boxes'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/products"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/products'
                          }
                        >
                          <ListItemText primary={'Products'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/product-tag-pages"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/product-tag-pages'
                          }
                        >
                          <ListItemText primary={'Product Tag Pages'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/memberships"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/memberships'
                          }
                        >
                          <ListItemText primary={'Memberships'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/shipping"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/shipping'
                          }
                        >
                          <ListItemText primary={'Shipping'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/shipping-insurance"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/shipping-insurance'
                          }
                        >
                          <ListItemText primary={'Shipping Insurance'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/cart-featured-products"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/cart-featured-products'
                          }
                        >
                          <ListItemText primary={'Cart Products'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/free-products"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/free-products'
                          }
                        >
                          <ListItemText primary={'Free Products'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/coupon-codes"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/coupon-codes'
                          }
                        >
                          <ListItemText primary={'Coupon Codes'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/gift-cards"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/gift-cards'
                          }
                        >
                          <ListItemText primary={'Gift Cards'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/order-bumps"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/order-bumps'
                          }
                        >
                          <ListItemText primary={'Order Bumps'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/one-click-upsells"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/one-click-upsells'
                          }
                        >
                          <ListItemText primary={'One Click Upsells'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/subscription-downsells"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/subscription-downsells'
                          }
                        >
                          <ListItemText primary={'Subs Downsells'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/subscription-downsells-v2"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/subscription-downsells-v2'
                          }
                        >
                          <ListItemText primary={'Subs Downsells V2'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/account-credits"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/account-credits'
                          }
                        >
                          <ListItemText primary="Account Credits" />
                        </ListItem>
                      </Link>
                    </>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/custom-thank-you-pages"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/custom-thank-you-pages'
                          }
                        >
                          <ListItemText primary={'Thank You Pages'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/checkout-custom-content"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/checkout-custom-content'
                          }
                        >
                          <ListItemText primary={'Checkout Content'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content') ||
                    this.state.access.includes('product-reviews')
                  ) ? null : (
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/shop/product-reviews"
                    >
                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ mobileOpen: false });
                        }}
                        style={{ paddingLeft: 25, marginTop: 1 }}
                        selected={
                          window.location.pathname === '/shop/product-reviews'
                        }
                      >
                        <ListItemText primary={'Product Reviews'} />
                      </ListItem>
                    </Link>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content') ||
                    this.state.access.includes('shop-data')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/shop-page"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname === '/shop/shop-page'
                          }
                        >
                          <ListItemText primary={'Shop Page'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/shop/subscriptions-page"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/shop/subscriptions-page'
                          }
                        >
                          <ListItemText primary={'Subscriptions Page'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('order-data') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('financial') ||
          this.state.access.includes('shop-data')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showWholesaleTabs: !this.state.showWholesaleTabs,
                  });
                }}
                selected={window.location.pathname.includes('/wholesale')}
              >
                <ListItemText primary={'Wholesale'} />
                {this.state.showWholesaleTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showWholesaleTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('reports') ||
                    this.state.access.includes('financial')
                  ) ? null : (
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/wholesale/products"
                    >
                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ mobileOpen: false });
                        }}
                        style={{ paddingLeft: 25, marginTop: 1 }}
                        selected={
                          window.location.pathname === '/wholesale/products'
                        }
                      >
                        <ListItemText primary={'Products'} />
                      </ListItem>
                    </Link>
                  )}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('reports') ||
                    this.state.access.includes('financial')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/wholesale/wholesalers"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/wholesale/wholesalers'
                          }
                        >
                          <ListItemText primary={'Wholesalers'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/wholesale/notifications"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/wholesale/notifications'
                          }
                        >
                          <ListItemText primary={'Notifications'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('shop-data')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showRewardsTabs: !this.state.showRewardsTabs,
                  });
                }}
                selected={window.location.pathname.includes('/rewards')}
              >
                <ListItemText primary={'Rewards'} />
                {this.state.showRewardsTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showRewardsTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/rewards/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/rewards/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/rewards/settings"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/rewards/settings'
                      }
                    >
                      <ListItemText primary={'Settings'} />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showServicesTabs: !this.state.showServicesTabs,
                  });
                }}
                selected={window.location.pathname.includes('/services')}
              >
                <ListItemText primary={'Services'} />
                {this.state.showServicesTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showServicesTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/services/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/services/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/services/service-categories"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={window.location.pathname.includes(
                        '/services/service-categories',
                      )}
                    >
                      <ListItemText primary={'Service Categories'} />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('deploy')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showWebsiteContentTabs: !this.state.showWebsiteContentTabs,
                  });
                }}
                selected={window.location.pathname.includes('/website-content')}
              >
                <ListItemText primary={'Website Content'} />
                {this.state.showWebsiteContentTabs ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.showWebsiteContentTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/website-content/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/website-content/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/home-page"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/page-home'
                          }
                        >
                          <ListItemText primary={'Home Page'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/home-page-v2"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/page-home-v2'
                          }
                        >
                          <ListItemText primary={'Home Page V2'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/feed-content"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/website-content/feed-content',
                          )}
                        >
                          <ListItemText primary={'Feed Content'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/redirect-manager"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/redirect-manager'
                          }
                        >
                          <ListItemText primary={'Redirect Manager'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/pages"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/pages'
                          }
                        >
                          <ListItemText primary={'Pages'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/global-blocks"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/global-blocks'
                          }
                        >
                          <ListItemText primary={'Global Blocks'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/delete-templates"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/delete-templates'
                          }
                        >
                          <ListItemText primary={'Delete Templates'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/header-banners"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/header-banners'
                          }
                        >
                          <ListItemText primary={'Header Banners'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/app-banner"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/app-banner'
                          }
                        >
                          <ListItemText primary={'App Banner'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/sounds"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/sounds'
                          }
                        >
                          <ListItemText primary={'Sounds'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/task-manager"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/task-manager'
                          }
                        >
                          <ListItemText primary={'Task Manager'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/website-content/push-notifications"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/website-content/push-notifications'
                          }
                        >
                          <ListItemText primary={'Push Notifications'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('deploy')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showMarketingToolsTabs: !this.state.showMarketingToolsTabs,
                  });
                }}
                selected={window.location.pathname.includes('/marketing-tools')}
              >
                <ListItemText primary={'Marketing Tools'} />
                {this.state.showMarketingToolsTabs ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.showMarketingToolsTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {/* <Link style={{textDecoration: 'none', color: '#000'}} to="/marketing-tools/dashboard">
                    <ListItem button onClick={() => { this.setState({mobileOpen: false}) }} style={{paddingLeft: 25, marginTop: 1}} selected={window.location.pathname === '/marketing-tools/dashboard'}>
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link> */}
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/marketing-tools/forms"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/marketing-tools/forms',
                          )}
                        >
                          <ListItemText primary={'Forms'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/marketing-tools/convert-box-sendlane-fields"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/marketing-tools/convert-box-sendlane-fields',
                          )}
                        >
                          <ListItemText primary={'ConvertBox Fields'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/marketing-tools/typeform-sendlane-fields"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={window.location.pathname.includes(
                            '/marketing-tools/typeform-sendlane-fields',
                          )}
                        >
                          <ListItemText primary={'Typeform Fields'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showPartnersTabs: !this.state.showPartnersTabs,
                  });
                }}
                selected={window.location.pathname.includes('/partners')}
              >
                <ListItemText primary={'Partners'} />
                {this.state.showPartnersTabs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.showPartnersTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/partners/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/partners/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/partners/manage-partners"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/partners/manage-partners'
                      }
                    >
                      <ListItemText primary={'Manage Partners'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/partners/courses"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={window.location.pathname.includes(
                        '/partners/courses',
                      )}
                    >
                      <ListItemText primary={'Associate Courses'} />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports') ||
          this.state.access.includes('shop-data')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showRetailMicrositeTabs:
                      !this.state.showRetailMicrositeTabs,
                  });
                }}
                selected={window.location.pathname.includes(
                  '/retail-microsite',
                )}
              >
                <ListItemText primary={'Retail Microsite'} />
                {this.state.showRetailMicrositeTabs ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.showRetailMicrositeTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/retail-microsite/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/retail-microsite/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/retail-microsite/settings"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/retail-microsite/settings'
                      }
                    >
                      <ListItemText primary={'Settings'} />
                    </ListItem>
                  </Link>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/retail-microsite/retailers"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname ===
                        '/retail-microsite/retailers'
                      }
                    >
                      <ListItemText primary={'Retailers'} />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin ||
          this.state.access.includes('content') ||
          this.state.access.includes('reports')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showBlogContentTabs: !this.state.showBlogContentTabs,
                  });
                }}
                selected={window.location.pathname.includes('/blog-content')}
              >
                <ListItemText
                  primary={
                    <span>
                      Blog{' '}
                      {!this.props.notifications
                        .blogNotificationCount ? null : (
                        <span className="notification-count-container">
                          {this.props.notifications.blogNotificationCount}
                        </span>
                      )}
                    </span>
                  }
                />
                {this.state.showBlogContentTabs ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.showBlogContentTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/blog-content/dashboard"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ mobileOpen: false });
                      }}
                      style={{ paddingLeft: 25, marginTop: 1 }}
                      selected={
                        window.location.pathname === '/blog-content/dashboard'
                      }
                    >
                      <ListItemText primary={'Dashboard'} />
                    </ListItem>
                  </Link>
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('content')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/blog-content/blog-posts"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/blog-content/blog-posts'
                          }
                        >
                          <ListItemText primary={'Blog Posts'} />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/blog-content/comments"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/blog-content/comments'
                          }
                        >
                          <ListItemText
                            primary={
                              <span>
                                Comments{' '}
                                {!this.props.notifications
                                  .pendingBlogNotificationCount ? null : (
                                  <span className="notification-count-container">
                                    {
                                      this.props.notifications
                                        .pendingBlogNotificationCount
                                    }
                                  </span>
                                )}
                              </span>
                            }
                          />
                        </ListItem>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/blog-content/side-bars"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/blog-content/side-bars'
                          }
                        >
                          <ListItemText primary={'Side Bars'} />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        {!(
          this.state.isSuperAdmin || this.state.access.includes('order-data')
        ) ? null : (
          <>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  this.setState({
                    showCustomerServiceTabs:
                      !this.state.showCustomerServiceTabs,
                  });
                }}
                selected={window.location.pathname.includes(
                  '/customer-service',
                )}
              >
                <ListItemText
                  primary={
                    <span>
                      Customer Svc{' '}
                      {!this.props.notifications
                        .failedOrdoroOrderCount ? null : (
                        <span className="notification-count-container">
                          {this.props.notifications.failedOrdoroOrderCount}
                        </span>
                      )}
                    </span>
                  }
                />
                {this.state.showCustomerServiceTabs ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.showCustomerServiceTabs}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                >
                  {!(
                    this.state.isSuperAdmin ||
                    this.state.access.includes('order-data')
                  ) ? null : (
                    <>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/customer-service/ordoro-order-failures"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            this.setState({ mobileOpen: false });
                          }}
                          style={{ paddingLeft: 25, marginTop: 1 }}
                          selected={
                            window.location.pathname ===
                            '/customer-service/ordoro-order-failures'
                          }
                        >
                          <ListItemText
                            primary={
                              <span>
                                Ordoro Failures{' '}
                                {!this.props.notifications
                                  .failedOrdoroOrderCount ? null : (
                                  <span className="notification-count-container">
                                    {
                                      this.props.notifications
                                        .failedOrdoroOrderCount
                                    }
                                  </span>
                                )}
                              </span>
                            }
                          />
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </>
        )}
        <Divider />
      </div>
    );
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            <CssBaseline />
            <AppBar position="fixed">
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={this.handleDrawerToggle.bind(this)}
                >
                  <MenuIcon />
                </IconButton>
                <Button
                  style={{ position: 'absolute', right: '20px' }}
                  onClick={() => {
                    this.props.logOut();
                  }}
                  color="inherit"
                >
                  Logout
                </Button>
              </Toolbar>
            </AppBar>
            <nav aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor={'left'}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle.bind(this)}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {this.drawer()}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer variant="permanent" open>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      height: '64px',
                      width: '168px',
                    }}
                  ></div>
                  {this.drawer()}
                </Drawer>
              </Hidden>
            </nav>

            <div className="main-container">
              <Switch>
                <Route path="/course" component={Course} />
                <Route path="/behavior" component={Behavior} />
                <Route path="/new-dog-course" component={NewPup} />
                <Route path="/quizzes" component={Quizzes} />
                <Route path="/products" component={ProductList} />
                <Route path="/checkout-products" component={CheckoutProducts} />
                <Route path="/one-click-upsell" component={OneClickUpsell} />
                <Route path="/academy-content" component={AcademyContent} />
                <Route path="/community" component={Community} />
                <Route path="/reports" component={Reports} />
                <Route path="/file-manager" component={FileManager} />
                <Route path="/blog-content" component={BlogContent} />
                <Route path="/customer-service" component={CustomerService} />
                <Route path="/rewards" component={Rewards} />
                <Route path="/services" component={Services} />
                <Route path="/partners" component={Partners} />
                <Route path="/retail-microsite" component={RetailMicrosite} />
                <Route path="/website-content" component={WebsiteContent} />
                <Route path="/marketing-tools" component={MarketingTools} />
                <Route path="/shop" component={Shop} />
                <Route path="/wholesale" component={Wholesale} />
                <Route path="/manage-users" component={UserManager} />
                <Route component={Welcome} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    currentUserAccess: state.currentUser.access,
    communityNotifications: state.communityNotifications,
  };
};

export default connect(mapStateToProps, {
  setNotifications,
  setCurrentUserAccess,
  setCommunityNotifications,
})(Authed);
