import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Calendar from 'react-calendar';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import {
  Button,
  Slide,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './subscription.scss';
import { config } from '../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const intervals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const periods = ['day', 'week', 'month'];

function Subscription(props) {
  const delayedGetTax = useCallback(debounce((d) => getTaxAmount(d), 500), []);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscription, setSubscription] = useState({
    created: {},
    shipping: {},
    billing: {},
    products: [{}],
    subtotal: 0,
    shippingAmount: 0,
    taxAmount: 0,
    total: 0,
  });
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [editAddressType, setEditAddressType] = useState('');
  const [editAddress, setEditAddress] = useState({
    firstName: '',
    lastName: '',
    address: '',
    addressSecondary: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });
  const [editQuantitiesModalOpen, setEditQuantitiesModalOpen] = useState(false);
  const [editSubscription, setEditSubscription] = useState(null);
  const [shippingConfig, setShippingConfig] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [updatedShippingCost, setUpdatedShippingCost] = useState(0);
  const [updatedTaxCost, setUpdatedTaxCost] = useState(0);
  const [taxLoading, setTaxLoading] = useState(false);
  const [updatedInterval, setUpdatedInterval] = useState(false);
  const [updatedPeriod, setUpdatedPeriod] = useState(false);
  const [whenToUpdateFrequency, setWhenToUpdateFrequency] = useState('now');
  const [showShipNowModal, setShowShipNowModal] = useState(false);
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [showSkipRenewal, setShowSkipRenewal] = useState(false);
  const [showChangeRenewalDate, setShowChangeRenewalDate] = useState(false);
  const [updatedNextRenewalDate, setUpdatedNextRenewalDate] = useState(new Date());
  const [membershipsToCancel, setMembershipsToCancel] = useState([]);
  const [cancelSubscriptionOpen, setCancelSubscriptionOpen] = useState(false);
  const [membershipsToReactivate, setMembershipsToReactivate] = useState([]);
  const [reactivateSubscriptionOpen, setReactivateSubscriptionOpen] = useState(false);
  const [editPrice, setEditPrice] = useState('');
  const [editPriceIndex, setEditPriceIndex] = useState(-1);

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/shop/subscriptions');
        return;
      }

      const subscriptionSnapshot = await db.collection('subscriptions').doc(id).get();
      const data = subscriptionSnapshot.data();
      const subscriptionData = {
        ...data,
        id: subscriptionSnapshot.id,
      };

      const orderSnapshot = await db.collection('orders-v2').doc(data.parentOrderId).get();
      const ordersSnapshot = await db.collection('orders-v2').where('parentOrderId', '==', data.parentOrderId).get();
      const parentOrderData = orderSnapshot.data();
      const ordersData = [];

      if (parentOrderData) {
        ordersData.push({
          ...parentOrderData,
          id: orderSnapshot.id,
        });
      }

      ordersSnapshot.forEach(o => {
        ordersData.push({
          ...o.data(),
          id: o.id,
        });
      });

      setSubscription(subscriptionData);
      setOrders(ordersData);

      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();

      const onlyPhysicalProducts = querySnapshot.data().simplifiedProducts.filter(p => {
        return !p.isDigital;
      });

      setProducts(onlyPhysicalProducts);

      const configSnapshot = await db.collection('config').doc('shipping').get();

      const shippingConfigData = configSnapshot.data();
      let countries = [];
      let shippingStates = [];

      for (let key in shippingConfigData.regions) {
        countries.push({
          value: key,
          name: shippingConfigData.regions[key].name,
        });

        // Default to US
        if (key === 'US') {
          let states = shippingConfigData.regions[key].states;

          for (let state in states) {
            shippingStates.push({
              value: state,
              name: states[state],
            });
          }
        }
      }

      countries = countries.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        return 0;
      });

      shippingStates = shippingStates.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setShippingConfig(shippingConfigData);
      setCountries(countries);
      setStates(shippingStates);
      setLoading(false);
    } catch (e) {
      console.log('error getting subscriptions', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this subscription. Please refresh and try again.');
    }
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;

    const states = shippingConfig.regions[country].states;

    let shippingStates = [];

    for (let state in states) {
      shippingStates.push({
        value: state,
        name: states[state],
      });
    }

    shippingStates = shippingStates.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      return 0;
    });

    setEditAddress({
      ...editAddress,
      country,
      state: shippingStates[0].value,
    });
    setStates(shippingStates);
  };

  useEffect(() => {
    if (editAddressType === 'billing') {
      return;
    }

    setTaxLoading(true);
    delayedGetTax({
      shipping: editAddress,
      subtotal: subscription.subtotal,
      shippingAmount: updatedShippingCost,
    });
  }, [editAddress, updatedShippingCost]);

  const getTaxAmount = async (data) => {
    if (data.shipping.country && data.shipping.state && data.shipping.zip) {
      try {
        const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
          country: data.shipping.country,
          state: data.shipping.state,
          zip: data.shipping.zip,
          city: data.shipping.city,
          street: data.shipping.street,
          amount: +data.subtotal,
          shipping: +data.shippingAmount,
          isDigital: false,
        });

        if (result.data.tax && result.data.tax.amount_to_collect) {
          setUpdatedTaxCost(result.data.tax.amount_to_collect);
        } else {
          setUpdatedTaxCost(0);
        }
      } catch (e) {
        console.log('e', e);
        setUpdatedTaxCost(0);
      }
    } else {
      setUpdatedTaxCost(0);
    }

    setTaxLoading(false);
  };

  useEffect(() => {
    if (!shippingConfig.zones || !editAddress.state || editAddressType === 'billing') {
      return;
    }

    const zones = shippingConfig.zones;

    zones.forEach(zone => {
      const regions = zone.regions;

      for (let key in regions) {
        const states = regions[key].states;

        for (let state in states) {
          if (state === editAddress.state) {
            setUpdatedShippingCost(calculateUpdatedShipping(subscription.products, zone));
          }
        }
      }
    });
  }, [editAddress.state]);

  const calculateUpdatedShipping = (products, zone) => {
    if (!products || !zone) {
      return 0;
    }

    const productClassMap = {};

    products.forEach(p => {
      if (p.isDigital) {
        return;
      }

      if (productClassMap[p.shippingClass]) {
        productClassMap[p.shippingClass].push(p);
      } else {
        productClassMap[p.shippingClass] = [p];
      }
    });

    const classes = zone.classes.map(c => {
      return {
        ...c,
        products: productClassMap[c.selectedClass] || [],
      };
    }).filter(c => c.products.length);

    let shippingCost = 0;

    for (let i = 0; i < classes.length; i++) {
      const rowCost = +classes[i].rowCost;
      const itemCost = +classes[i].itemCost;
      const min = +classes[i].min;
      const max = +classes[i].max;
      let itemCount = 0;

      classes[i].products.forEach(p => {
        itemCount += +p.quantity;
      });

      if (itemCount > max) {
        itemCount = max;
      }

      itemCount = itemCount - (min - 1);

      shippingCost += (rowCost + (itemCost * itemCount));

      if (classes[i].abort) {
        return 0;
      }

      if (classes[i].break) {
        return shippingCost;
      }
    }

    return shippingCost;
  };

  const submitEditAddress = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const dataToUpdate = {
        [editAddressType]: editAddress,
      };

      if (editAddressType === 'shipping') {
        const newTotal = (+subscription.subtotal) + (Math.round((+updatedShippingCost + Number.EPSILON) * 100) / 100) + (Math.round((+updatedTaxCost + Number.EPSILON) * 100) / 100);

        dataToUpdate.shippingAmount = (Math.round((+updatedShippingCost + Number.EPSILON) * 100) / 100);
        dataToUpdate.taxAmount = (Math.round((+updatedTaxCost + Number.EPSILON) * 100) / 100);
        dataToUpdate.total = (Math.round((+newTotal + Number.EPSILON) * 100) / 100);
        dataToUpdate.updated = firebase.firestore.Timestamp.fromDate(new Date());
      }

      await db.collection('subscriptions').doc(subscription.id).update(dataToUpdate);

      setSubscription({
        ...subscription,
        ...dataToUpdate,
      });
      setLoading(false);
      setEditAddressType('');
    } catch (e) {
      console.log('address error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the address. Please try again.');
    }
  };

  const submitUpdateSubscriptionQuantities = async () => {
    const db = firebase.firestore();
    setEditQuantitiesModalOpen(false);
    setLoading(true);

    try {
      const updatedProducts = editSubscription.products.map((p) => {
        return {
          isDigital: p.isDigital || false,
          name: p.name,
          price: p.price,
          productId: p.productId,
          shippingClass: p.shippingClass || '',
          sku: p.sku,
          quantity: p.quantity,
        };
      });

      const itemsToUpdate = {
        products: updatedProducts,
        subtotal: editSubscription.subtotal,
        shippingAmount: editSubscription.shippingAmount,
        taxAmount: editSubscription.taxAmount,
        total: editSubscription.total,
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
      };

      await db.collection('subscriptions').doc(editSubscription.id).update(itemsToUpdate);

      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
    } catch (e) {
      console.log('error updating subscription', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the subscription. Please try again.');
    }
  };

  const initializeCancelSubscription = async () => {
    const db = firebase.firestore();
    setLoading(true);

    const productIds = subscription.products.map(p => p.productId);

    const canceledProductMemberships = [];

    for (let i = 0; i < productIds.length; i++) {
      try {
        const productSnapshot = await db.collection('products-v2').doc(productIds[i]).get();
        const product = productSnapshot.data();

        if (product && product.memberships && Array.isArray(product.memberships) && product.memberships.length) {
          for (let j = 0; j < product.memberships.length; j++) {
            canceledProductMemberships.push(product.memberships[j]);
          }
        }
      } catch (e) {
        console.log('error getting memberships', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error canceling this subscription, please try again.');
        return;
      }
    }

    const foundMembershipsToCancel = [];

    if (canceledProductMemberships.length) {
      for (let i = 0; i < canceledProductMemberships.length; i++) {
        try {
          const snapshot = await db.collection('user-memberships').where('membership', '==', canceledProductMemberships[i]).where('userId', '==', subscription.userId).get();

          snapshot.docs.forEach(doc => {
            foundMembershipsToCancel.push(doc.id);
          });
        } catch (e) {
          console.log('error getting memberships to cancel', e);
          setLoading(false);
          setModalTitle('Error:');
          setModalText('There was an error canceling this subscription, please try again.');
          return;
        }
      }
    }

    setMembershipsToCancel(foundMembershipsToCancel);
    setCancelSubscriptionOpen(true);
    setLoading(false);
  };

  const confirmCancelSubscription = async () => {
    const db = firebase.firestore();
    setLoading(true);

    if (membershipsToCancel.length) {
      const expires = subscription.nextPaymentDate;
      let hadErrorsCancelingMembership = false;

      for (let i = 0; i < membershipsToCancel.length; i++) {
        try {
          await db.collection('user-memberships').doc(membershipsToCancel[i]).update({
            active: false,
            expires,
            updatedBy: firebase.auth().currentUser.email || null,
            updated: Date.now(),
          });
        } catch (e) {
          console.log('error canceling membership', e);
          hadErrorsCancelingMembership = true;
        }
      }

      if (hadErrorsCancelingMembership) {
        for (let i = 0; i < membershipsToCancel.length; i++) {
          try {
            await db.collection('user-memberships').doc(membershipsToCancel[i]).update({
              active: true,
              expires: null,
              updatedBy: firebase.auth().currentUser.email || null,
              updated: Date.now(),
            });
          } catch (e) {
            console.log('error reactivating membership', e);
          }
        }

        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error canceling your subscription, please try again. If this problem persists, please contact us.');
        return;
      }
    }

    try {
      const itemsToUpdate = {
        active: false,
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        canceledDate: Date.now(),
      };

      await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);

      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
      closeSubscriptionCancelModel();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error canceling your subscription, please try again. If this problem persists, please contact us.');
    }
  };

  const closeSubscriptionCancelModel = () => {
    setMembershipsToCancel([]);
    setCancelSubscriptionOpen(false);
  };

  const initializeReactivateSubscription = async () => {
    const db = firebase.firestore();
    setLoading(true);

    const productIds = subscription.products.map(p => p.productId);

    const reactivatedProductMemberships = [];

    for (let i = 0; i < productIds.length; i++) {
      try {
        const productSnapshot = await db.collection('products-v2').doc(productIds[i]).get();
        const product = productSnapshot.data();

        if (product && product.memberships && Array.isArray(product.memberships) && product.memberships.length) {
          for (let j = 0; j < product.memberships.length; j++) {
            reactivatedProductMemberships.push(product.memberships[j]);
          }
        }
      } catch (e) {
        console.log('error getting memberships', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error reactivating this subscription, please try again.');
        return;
      }
    }

    const foundMembershipsToReactivate = [];

    if (reactivatedProductMemberships.length) {
      for (let i = 0; i < reactivatedProductMemberships.length; i++) {
        try {
          const snapshot = await db.collection('user-memberships').where('membership', '==', reactivatedProductMemberships[i]).where('userId', '==', firebase.auth().currentUser.uid).get();

          snapshot.docs.forEach(doc => {
            foundMembershipsToReactivate.push(doc.id);
          });
        } catch (e) {
          console.log('error getting memberships to cancel', e);
          setLoading(false);
          setModalTitle('Error:');
          setModalText('There was an error reactivating your subscription, please try again. If this problem persists, please contact us.');
          return;
        }
      }
    }

    setMembershipsToReactivate(foundMembershipsToReactivate);
    setReactivateSubscriptionOpen(true);
    setLoading(false);
  };

  const closeSubscriptionReactivateModel = () => {
    setMembershipsToReactivate([]);
    setReactivateSubscriptionOpen(false);
  };

  const confirmReactivateSubscription = async () => {
    const db = firebase.firestore();
    setLoading(true);

    if (membershipsToReactivate.length) {
      const expires = subscription.nextPaymentDate;
      let hadErrorsReactivatingMembership = false;

      for (let i = 0; i < membershipsToReactivate.length; i++) {
        try {
          await db.collection('user-memberships').doc(membershipsToReactivate[i]).update({
            active: true,
            expires: null,
            updatedBy: firebase.auth().currentUser.email || null,
            updated: Date.now(),
          });
        } catch (e) {
          console.log('error reactivating membership', e);
          hadErrorsReactivatingMembership = true;
        }
      }

      if (hadErrorsReactivatingMembership) {
        for (let i = 0; i < membershipsToReactivate.length; i++) {
          try {
            await db.collection('user-memberships').doc(membershipsToReactivate[i]).update({
              active: false,
              expires,
              updatedBy: firebase.auth().currentUser.email || null,
              updated: Date.now(),
            });
          } catch (e) {
            console.log('error reactivating membership', e);
          }
        }

        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error reactivating this subscription, please try again.');
        return;
      }
    }

    try {
      const itemsToUpdate = {
        active: true,
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        canceledDate: null,
      };

      if (subscription.nextPaymentDate < Date.now()) {
        const subscriptionBeingUpdated = subscription;
        itemsToUpdate.nextPaymentDate = moment().add(subscriptionBeingUpdated.interval, `${subscriptionBeingUpdated.period}s`).valueOf();

        const products = subscriptionBeingUpdated.products.map(p => {
          return {
            ...p,
            paidPrice: p.price,
            id: p.productId,
          };
        });
  
        try {
          if (subscriptionBeingUpdated.paymentType === 'paypal') {
            await db.collection('orders-v2').add({
              status: 'READY_FOR_PAYPAL_PAYMENT',
              parentOrderId: subscriptionBeingUpdated.parentOrderId,
              paypalId: subscriptionBeingUpdated.paypalId,
              userId: subscriptionBeingUpdated.userId,
              userEmail: subscriptionBeingUpdated.userEmail,
              email: subscriptionBeingUpdated.email,
              phone: subscriptionBeingUpdated.phone,
              paymentType: 'paypal',
              products,
              couponsUsed: [],
              total: subscriptionBeingUpdated.total.toFixed(2),
              subtotal: subscriptionBeingUpdated.subtotal.toFixed(2),
              taxAmount: subscriptionBeingUpdated.taxAmount.toFixed(2),
              shippingAmount: subscriptionBeingUpdated.shippingAmount.toFixed(2),
              shipping: subscriptionBeingUpdated.shipping,
              billing: subscriptionBeingUpdated.billing,
              subscriptions: [],
              updated: firebase.firestore.Timestamp.fromDate(new Date()),
              created: firebase.firestore.Timestamp.fromDate(new Date()),
            });
          } else if (subscriptionBeingUpdated.paymentType === 'stripe') {
            await db.collection('orders-v2').add({
              status: 'READY_FOR_STRIPE_PAYMENT',
              parentOrderId: subscriptionBeingUpdated.parentOrderId,
              stripeCardId: subscriptionBeingUpdated.stripeCardId,
              stripeCustomerId: subscriptionBeingUpdated.stripeCustomerId,
              userId: subscriptionBeingUpdated.userId,
              userEmail: subscriptionBeingUpdated.userEmail,
              email: subscriptionBeingUpdated.email,
              phone: subscriptionBeingUpdated.phone,
              paymentType: 'stripe',
              products,
              couponsUsed: [],
              total: subscriptionBeingUpdated.total.toFixed(2),
              subtotal: subscriptionBeingUpdated.subtotal.toFixed(2),
              taxAmount: subscriptionBeingUpdated.taxAmount.toFixed(2),
              shippingAmount: subscriptionBeingUpdated.shippingAmount.toFixed(2),
              shipping: subscriptionBeingUpdated.shipping,
              billing: subscriptionBeingUpdated.billing,
              subscriptions: [],
              updated: firebase.firestore.Timestamp.fromDate(new Date()),
              created: firebase.firestore.Timestamp.fromDate(new Date()),
            });
          }
        } catch (e) {
          if (membershipsToReactivate.length) {
            for (let i = 0; i < membershipsToReactivate.length; i++) {
              try {
                await db.collection('user-memberships').doc(membershipsToReactivate[i]).update({
                  active: false,
                  expires: subscription.nextPaymentDate,
                  updatedBy: firebase.auth().currentUser.email || null,
                  updated: Date.now(),
                });
              } catch (e) {
                console.log('error reactivating membership', e);
              }
            }
          }

          console.log('error creating order in subscription reactivation', e);
          setLoading(false);
          setModalTitle('Error:');
          setModalText('There was an error reactivating this subscription, please try again.');
          return;
        }
      }

      await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);

      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
      closeSubscriptionReactivateModel();
    } catch (e) {
      console.log('error reactivating', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reactivating this subscription, please try again.');
    }
  };

  const shipNow = async (update) => {
    const db = firebase.firestore();
    setShowShipNowModal(false);
    setLoading(true);

    try {
      const products = subscription.products.map(p => {
        return {
          ...p,
          paidPrice: p.price,
          id: p.productId,
        };
      });

      if (subscription.paymentType === 'paypal') {
        await db.collection('orders-v2').add({
          status: 'READY_FOR_PAYPAL_PAYMENT',
          parentOrderId: subscription.parentOrderId,
          paypalId: subscription.paypalId,
          userId: subscription.userId,
          userEmail: subscription.userEmail,
          email: subscription.email,
          phone: subscription.phone,
          paymentType: 'paypal',
          products,
          couponsUsed: [],
          total: subscription.total.toFixed(2),
          subtotal: subscription.subtotal.toFixed(2),
          taxAmount: subscription.taxAmount.toFixed(2),
          shippingAmount: subscription.shippingAmount.toFixed(2),
          shipping: subscription.shipping,
          billing: subscription.billing,
          subscriptions: [],
          updated: firebase.firestore.Timestamp.fromDate(new Date()),
          created: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      } else if (subscription.paymentType === 'stripe') {
        await db.collection('orders-v2').add({
          status: 'READY_FOR_STRIPE_PAYMENT',
          parentOrderId: subscription.parentOrderId,
          stripeCardId: subscription.stripeCardId,
          stripeCustomerId: subscription.stripeCustomerId,
          userId: subscription.userId,
          userEmail: subscription.userEmail,
          email: subscription.email,
          phone: subscription.phone,
          paymentType: 'stripe',
          products,
          couponsUsed: [],
          total: subscription.total.toFixed(2),
          subtotal: subscription.subtotal.toFixed(2),
          taxAmount: subscription.taxAmount.toFixed(2),
          shippingAmount: subscription.shippingAmount.toFixed(2),
          shipping: subscription.shipping,
          billing: subscription.billing,
          subscriptions: [],
          updated: firebase.firestore.Timestamp.fromDate(new Date()),
          created: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      } else if (subscription.paymentType === 'applePay') {
        await db.collection('orders-v2').add({
          status: 'READY_FOR_STRIPE_PAYMENT',
          parentOrderId: subscription.parentOrderId,
          stripePaymentMethodId: subscription.stripePaymentMethodId,
          stripeCustomerId: subscription.stripeCustomerId,
          userId: subscription.userId,
          userEmail: subscription.userEmail,
          email: subscription.email,
          phone: subscription.phone,
          paymentType: 'applePay',
          products,
          couponsUsed: [],
          total: subscription.total.toFixed(2),
          subtotal: subscription.subtotal.toFixed(2),
          taxAmount: subscription.taxAmount.toFixed(2),
          shippingAmount: subscription.shippingAmount.toFixed(2),
          shipping: subscription.shipping,
          billing: subscription.billing,
          subscriptions: [],
          updated: firebase.firestore.Timestamp.fromDate(new Date()),
          created: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      }
    } catch (e) {
      console.log('error shipping subscription now', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this subscription, please try again.');
      return;
    }

    if (update || subscription.downsellV2AddedDate) {
      try {
        const itemsToUpdate = {
          nextPaymentDate: moment().add(subscription.interval, `${subscription.period}s`).valueOf(),
          updated: firebase.firestore.Timestamp.fromDate(new Date()),
        };

        if (
          subscription.downsellNumberOfRenewals &&
          subscription.downsellNumberOfTimesRenewed < subscription.downsellNumberOfRenewals
        ) {
          itemsToUpdate.downsellNumberOfTimesRenewed = subscription.downsellNumberOfTimesRenewed + 1;

          if (itemsToUpdate.downsellNumberOfTimesRenewed === subscription.downsellNumberOfRenewals) {
            itemsToUpdate.products = subscription.products.filter(p => {
              return !p.downsellId;
            });
          }
        }

        await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);
        
        setSubscription({
          ...subscription,
          ...itemsToUpdate,
        });
        setLoading(false);
      } catch (e) {
        console.log('error updating next payment date.', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`The order has been placed but here was an error updating the next subscription renewal date.`);
      }
    } else {
      setLoading(false);
    }
  };

  const skipRenewal = async () => {
    const db = firebase.firestore();
    setShowSkipRenewal(false);
    setLoading(true);

    try {
      const itemsToUpdate = {
        nextPaymentDate: moment(subscription.nextPaymentDate).add(subscription.interval, `${subscription.period}s`).valueOf(),
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
      };

      await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);

      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
    } catch (e) {
      console.log('error skipping subscription renewal', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating your subscription, please try again. If this problem persists, please contact us.');
    }
  };

  const updateNextRenewalDate = async () => {
    const db = firebase.firestore();
    setShowChangeRenewalDate(false);
    setLoading(true);

    try {
      const itemsToUpdate = {
        nextPaymentDate: moment(updatedNextRenewalDate).valueOf(),
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
      };

      await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);

      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
    } catch (e) {
      console.log('error updating next renewal date', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this subscription, please try again. If this problem persists, please contact us.');
    }
  };

  const updateFrequency = async () => {
    const db = firebase.firestore();
    setShowFrequencyModal(false);
    setLoading(true);

    if (whenToUpdateFrequency === 'now') {
      const products = subscription.products.map(p => {
        return {
          ...p,
          paidPrice: p.price,
          id: p.productId,
        };
      });

      try {
        if (subscription.paymentType === 'paypal') {
          await db.collection('orders-v2').add({
            status: 'READY_FOR_PAYPAL_PAYMENT',
            parentOrderId: subscription.parentOrderId,
            paypalId: subscription.paypalId,
            userId: subscription.userId,
            userEmail: subscription.userEmail,
            email: subscription.email,
            phone: subscription.phone,
            paymentType: 'paypal',
            products,
            couponsUsed: [],
            total: subscription.total.toFixed(2),
            subtotal: subscription.subtotal.toFixed(2),
            taxAmount: subscription.taxAmount.toFixed(2),
            shippingAmount: subscription.shippingAmount.toFixed(2),
            shipping: subscription.shipping,
            billing: subscription.billing,
            subscriptions: [],
            updated: firebase.firestore.Timestamp.fromDate(new Date()),
            created: firebase.firestore.Timestamp.fromDate(new Date()),
          });
        } else if (subscription.paymentType === 'stripe') {
          await db.collection('orders-v2').add({
            status: 'READY_FOR_STRIPE_PAYMENT',
            parentOrderId: subscription.parentOrderId,
            stripeCardId: subscription.stripeCardId,
            stripeCustomerId: subscription.stripeCustomerId,
            userId: subscription.userId,
            userEmail: subscription.userEmail,
            email: subscription.email,
            phone: subscription.phone,
            paymentType: 'stripe',
            products,
            couponsUsed: [],
            total: subscription.total.toFixed(2),
            subtotal: subscription.subtotal.toFixed(2),
            taxAmount: subscription.taxAmount.toFixed(2),
            shippingAmount: subscription.shippingAmount.toFixed(2),
            shipping: subscription.shipping,
            billing: subscription.billing,
            subscriptions: [],
            updated: firebase.firestore.Timestamp.fromDate(new Date()),
            created: firebase.firestore.Timestamp.fromDate(new Date()),
          });
        }
      } catch (e) {
        console.log('error shipping subscription now in update frequency', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error updating this subscription, please try again.');
        return;
      }
    }

    try {
      const itemsToUpdate = {
        interval: updatedInterval,
        period: updatedPeriod,
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
      };

      if (whenToUpdateFrequency === 'now') {
        itemsToUpdate.nextPaymentDate = moment().add(updatedInterval, `${updatedPeriod}s`).valueOf();
      }

      await db.collection('subscriptions').doc(subscription.id).update(itemsToUpdate);
      
      setSubscription({
        ...subscription,
        ...itemsToUpdate,
      });
      setLoading(false);
    } catch (e) {
      console.log('error updating subscription frequency', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this subscription, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Subscription">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/shop/subscriptions">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Subscriptions
          </Button>
        </Link>
      </div>

      {!subscription ? null :
        <div>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography style={{marginRight: 10}}>
              Subscription: <strong>{subscription.id}</strong>
            </Typography>

            <Typography variant="body2">
              <strong>Created:</strong> {moment(subscription.created.seconds * 1000).format('lll')}
            </Typography>
          </Toolbar>

          <div className="content-container">
            <Typography variant="body2">
              <strong>Status:</strong> <span style={{color: (!subscription.active && subscription.canceledReason !== 'Subscription Box Orders Completed') ? 'red' : '#7ED242'}}>{subscription.active ? 'Active' : (subscription.canceledReason === 'Subscription Box Orders Completed' ? 'Subscription Box Orders Completed' : 'Inactive')}</span>
            </Typography>
            {!subscription.canceledDate ? null :
              <Typography variant="body2" style={{marginTop: 10}}>
                <strong>Canceled Date:</strong> {moment(subscription.canceledDate).format('lll')}
              </Typography>
            }
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Customer Email:</strong> {subscription.email}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10, marginBottom: 20}}>
              <strong>Customer ID:</strong> {subscription.userId}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Frequency:</strong> Every {subscription.interval === 1 ? '' : subscription.interval} {subscription.period}{subscription.interval === 1 ? '' : 's'}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10, marginBottom: 20}}>
              <strong>Next Renewal:</strong> {subscription.active ? moment(subscription.nextPaymentDate).format('MMM Do, YYYY') : 'Inactive'}
            </Typography>

            <Divider/>

            <div className="section">
              <div>
                <Typography variant="h6" style={{marginBottom: 10}}>
                  <strong>Actions:</strong>
                </Typography>

                <div>
                  {(subscription.products[0].isDigital || subscription.isSubscriptionBox) ?
                    <>
                      {subscription.active ?
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => { initializeCancelSubscription() }}
                          color="secondary"
                        >
                          Cancel
                        </Button> :
                        <>
                          {(subscription.disableActivation || subscription.isSubscriptionBox) ? <p>This subscription cannot be reactivated</p> :
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => { initializeReactivateSubscription() }}
                              color="primary"
                            >
                              Reactivate
                            </Button>
                          }
                        </>
                      }
                    </> :
                    <>
                      {subscription.active ?
                        <>
                          <Button
                            style={{marginRight: 10, marginBottom: 5}}
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setShowShipNowModal(true);
                            }}
                            color="primary"
                          >
                            Ship Now
                          </Button>
                          <Button
                            style={{marginRight: 10, marginBottom: 5}}
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setUpdatedInterval(subscription.interval);
                              setUpdatedPeriod(subscription.period);
                              setWhenToUpdateFrequency('now');
                              setShowFrequencyModal(true);
                            }}
                            color="primary"
                          >
                            Change Frequency
                          </Button>
                          <Button
                            style={{marginRight: 10, marginBottom: 5}}
                            variant="contained"
                            size="small"
                            onClick={() => { setShowSkipRenewal(true) }}
                            color="primary"
                          >
                            Skip Next Renewal
                          </Button>
                          <Button
                            style={{marginRight: 10, marginBottom: 5}}
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setUpdatedNextRenewalDate(new Date(subscription.nextPaymentDate));
                              setShowChangeRenewalDate(true);
                            }}
                            color="primary"
                          >
                            Change Renewal Date
                          </Button>
                          <Button
                            style={{marginRight: 10, marginBottom: 5}}
                            variant="contained"
                            size="small"
                            onClick={() => { initializeCancelSubscription() }}
                            color="secondary"
                          >
                            Cancel
                          </Button>
                        </> :
                        <>
                          {(subscription.disableActivation || subscription.isSubscriptionBox) ? <p>This subscription cannot be reactivated</p> :
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => { initializeReactivateSubscription() }}
                              color="primary"
                            >
                              Reactivate
                            </Button>
                          }
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>

            <Divider/>

            <div className="section">
              <div>
                <Typography variant="h6" style={{marginBottom: 10}}>
                  <strong>Summary:</strong>
                </Typography>

                <TableContainer component={Paper}>
                  <Table aria-label="summary table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Product Name</strong></TableCell>
                        <TableCell align="center"><strong>Quantity</strong></TableCell>
                        <TableCell align="right"><strong>Price</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscription.products.map((p, i) => (
                        <TableRow key={`product-${i}`}>
                          <TableCell>{p.name}
                          {!p.selectedBundleProducts ? null :
                            <div style={{marginTop: 5}}><strong>Products Included:</strong>
                              {p.selectedBundleProducts.map((b, i) => (
                                <div key={`bundle-product-${i}`} style={{marginTop: 5, marginLeft: 5}}>{i + 1}. {b.name}</div> ))
                              }
                            </div>
                          }
                          </TableCell>
                          <TableCell align="center">
                            {p.quantity}

                            {subscription.products[0].isDigital ? null :
                              <IconButton
                                style={{marginLeft: 10, color: '#000'}}
                                color="default"
                                aria-label="edit"
                                onClick={() => {
                                  setEditSubscription(cloneDeep(subscription));
                                  setEditQuantitiesModalOpen(true);
                                }}
                              >
                                <EditIcon style={{fontSize: 14, cursor: 'pointer'}} />
                              </IconButton>
                            }
                          </TableCell>
                          <TableCell align="right">
                            ${p.price}

                            {subscription.products[0].isDigital ? null :
                              <IconButton
                                style={{marginLeft: 10, color: '#000'}}
                                color="default"
                                aria-label="edit"
                                onClick={() => {
                                  setEditSubscription(cloneDeep(subscription));
                                  setEditQuantitiesModalOpen(true);
                                }}
                              >
                                <EditIcon style={{fontSize: 14, cursor: 'pointer'}} />
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>
                          {(subscription.products[0].isDigital || subscription.isSubscriptionBox) ? null :
                            <Button variant="contained" onClick={async () => {
                              setLoading(true);
                              const subscriptionToEdit = cloneDeep(subscription);
                              subscriptionToEdit.products.push({
                                editableProduct: true,
                                selectedProduct: products[0],
                                isDigital: products[0].isDigital,
                                name: products[0].name,
                                price: ((+products[0].price) - (+products[0].price * .15)).toFixed(2),
                                productId: products[0].id,
                                shippingClass: products[0].shippingClass,
                                sku: products[0].sku,
                                quantity: 1,
                              });

                              const productsCopy = [ ...subscriptionToEdit.products ];

                              const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                                return acc += (+curr.price * curr.quantity);
                              }, 0);

                              let shippingAmount = subscriptionToEdit.shippingAmount;

                              if (!shippingConfig.zones || !subscriptionToEdit.shipping.state) {
                                shippingAmount = subscriptionToEdit.shippingAmount;
                              } else {
                                const zones = shippingConfig.zones;

                                zones.forEach(zone => {
                                  const regions = zone.regions;

                                  for (let key in regions) {
                                    const states = regions[key].states;

                                    for (let state in states) {
                                      if (state === subscriptionToEdit.shipping.state) {
                                        shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                                      }
                                    }
                                  }
                                });
                              }

                              let taxAmount = subscriptionToEdit.taxAmount;

                              try {
                                const data = subscriptionToEdit;
                                const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                                  country: data.shipping.country,
                                  state: data.shipping.state,
                                  zip: data.shipping.zip,
                                  city: data.shipping.city,
                                  street: data.shipping.street,
                                  amount: +(calculatedSubtotal.toFixed(2)),
                                  shipping: +shippingAmount,
                                  isDigital: false,
                                });

                                if (result.data.tax && result.data.tax.amount_to_collect) {
                                  taxAmount = result.data.tax.amount_to_collect;
                                }
                              } catch (e) {
                                console.log('tax calculation error', e);
                              }

                              setEditSubscription({
                                ...subscriptionToEdit,
                                products: productsCopy,
                                subtotal: +(calculatedSubtotal.toFixed(2)),
                                shippingAmount: +shippingAmount,
                                taxAmount: +((+taxAmount).toFixed(2)),
                                total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
                              });
                              setLoading(false);
                              setEditQuantitiesModalOpen(true);
                            }} size="small" color="primary">
                              Add Products
                            </Button>
                          }
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="right">
                          <div className="subscription-summary">
                            <div className="totals-inner">
                              <div className="total-line">
                                <p>Subtotal:</p>
                                <p>${subscription.subtotal.toFixed(2)}</p>
                              </div>
                              <div className="total-line">
                                <p>Shipping:</p>
                                <p>${subscription.shippingAmount.toFixed(2)}</p>
                              </div>
                              <div className="total-line">
                                <p>Tax:</p>
                                <p>${subscription.taxAmount.toFixed(2)}</p>
                              </div>
                              <div className="total-line grand-total">
                                <p>Total:</p>
                                <p>${subscription.total.toFixed(2)}</p>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <Divider/>

            <div className="section">
              <Typography variant="h6" style={{marginBottom: 10}}>
                <strong>Addresses:</strong>
              </Typography>
              <Paper style={{padding: 16}}>
                <div className="address-container">
                  <Typography variant="body2" style={{marginBottom: 10}}>
                    <strong>Billing: </strong>
                    <EditIcon
                      style={{fontSize: 14, cursor: 'pointer'}}
                      onClick={() => {
                        setEditAddressType('billing');
                        setEditAddress({
                          ...subscription.billing,
                        });
                      }}
                    />
                  </Typography>
                  <Typography variant="body2">{subscription.billing.firstName} {subscription.billing.lastName}</Typography>
                  <Typography variant="body2">{subscription.billing.address} {subscription.billing.addressSecondary ? subscription.billing.addressSecondary : ''}</Typography>
                  <Typography variant="body2">{!subscription.billing.city ? null : <span>{subscription.billing.city}, {subscription.billing.state} {subscription.billing.zip}</span>}</Typography>
                  <Typography variant="body2">{subscription.billing.country}</Typography>
                </div>
                {subscription.products[0].isDigital ? null :
                  <div className="address-container">
                    <Typography variant="body2" style={{marginBottom: 10}}>
                      <strong>Shipping: </strong>
                      <EditIcon
                        style={{fontSize: 14, cursor: 'pointer'}}
                        onClick={() => {
                          if (!states.includes(subscription.shipping.state)) {
                            handleCountryChange({
                              target: {
                                value: subscription.shipping.country,
                              },
                            });
                          }
                          setEditAddressType('shipping');
                          setEditAddress({
                            ...subscription.shipping,
                          });
                        }}
                      />
                    </Typography>
                    <Typography variant="body2">{subscription.shipping.firstName} {subscription.shipping.lastName}</Typography>
                    <Typography variant="body2">{subscription.shipping.address} {subscription.shipping.addressSecondary ? subscription.shipping.addressSecondary : ''}</Typography>
                    <Typography variant="body2">{!subscription.shipping.city ? null : <span>{subscription.shipping.city}, {subscription.shipping.state} {subscription.shipping.zip}</span>}</Typography>
                    <Typography variant="body2">{subscription.shipping.country}</Typography>
                  </div>
                }
              </Paper>
            </div>

            <Divider/>

            <div className="section">
              <div>
                <Typography variant="h6" style={{marginBottom: 10}}>
                  <strong>Orders:</strong>
                </Typography>

                <TableContainer component={Paper}>
                  <Table aria-label="orders table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Order</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                        <TableCell><strong>User Email</strong></TableCell>
                        <TableCell><strong>User ID</strong></TableCell>
                        <TableCell><strong>Amount</strong></TableCell>
                        <TableCell padding="checkbox"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map((o, i) => (
                        <TableRow key={o.id}>
                          <TableCell>
                            <div><strong>ID:</strong> {o.id}</div>
                            <div style={{marginTop: 5}}><strong>Date:</strong> {moment(o.created.seconds * 1000).format('lll')}</div>
                          </TableCell>
                          <TableCell>{o.status}</TableCell>
                          <TableCell>{o.userEmail}</TableCell>
                          <TableCell>{o.userId}</TableCell>
                          <TableCell>${o.total}</TableCell>
                          <TableCell padding="checkbox">
                            <Link to={`/shop/orders/${o.id}`}>
                              <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      }

      <Dialog fullScreen open={editQuantitiesModalOpen} onClose={() => { setEditQuantitiesModalOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Edit Subscription Products</DialogTitle>
        <DialogContent>
          {!editSubscription ? null :
            <div className="subscription-summary">
              <TableContainer component={Paper}>
                <Table aria-label="summary table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Product Name</strong></TableCell>
                      <TableCell align="center"><strong>Quantity</strong></TableCell>
                      <TableCell align="right"><strong>Price</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editSubscription.products.map((p, i) => (
                      <TableRow key={`product-${i}`}>
                        <TableCell>
                          {!p.editableProduct ?
                            <span>{p.name}</span> :
                            <FormControl margin="dense" variant="outlined">
                              <Select
                                value={p.selectedProduct}
                                onChange={async (e) => {
                                  const newProduct = e.target.value;
                                  setLoading(true);
                                  const productsCopy = [ ...editSubscription.products ];
                                  productsCopy[i] = {
                                    editableProduct: true,
                                    selectedProduct: newProduct,
                                    isDigital: newProduct.isDigital,
                                    name: newProduct.name,
                                    price: ((+newProduct.price) - (+newProduct.price * .15)).toFixed(2),
                                    productId: newProduct.id,
                                    shippingClass: newProduct.shippingClass,
                                    sku: newProduct.sku,
                                    quantity: 1,
                                  };

                                  const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                                    return acc += (+curr.price * curr.quantity);
                                  }, 0);

                                  let shippingAmount = editSubscription.shippingAmount;

                                  if (!shippingConfig.zones || !editSubscription.shipping.state) {
                                    shippingAmount = editSubscription.shippingAmount;
                                  } else {
                                    const zones = shippingConfig.zones;

                                    zones.forEach(zone => {
                                      const regions = zone.regions;

                                      for (let key in regions) {
                                        const states = regions[key].states;

                                        for (let state in states) {
                                          if (state === editSubscription.shipping.state) {
                                            shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                                          }
                                        }
                                      }
                                    });
                                  }

                                  let taxAmount = editSubscription.taxAmount;

                                  try {
                                    const data = editSubscription;
                                    const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                                      country: data.shipping.country,
                                      state: data.shipping.state,
                                      zip: data.shipping.zip,
                                      city: data.shipping.city,
                                      street: data.shipping.street,
                                      amount: +(calculatedSubtotal.toFixed(2)),
                                      shipping: +shippingAmount,
                                      isDigital: false,
                                    });

                                    if (result.data.tax && result.data.tax.amount_to_collect) {
                                      taxAmount = result.data.tax.amount_to_collect;
                                    }
                                  } catch (e) {
                                    console.log('tax calculation error', e);
                                  }

                                  setEditSubscription({
                                    ...editSubscription,
                                    products: productsCopy,
                                    subtotal: +(calculatedSubtotal.toFixed(2)),
                                    shippingAmount: +shippingAmount,
                                    taxAmount: +((+taxAmount).toFixed(2)),
                                    total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
                                  });
                                  setLoading(false);
                                }}
                              >
                                {products.map((simplified, i) => {
                                  return (
                                    <MenuItem key={`select-product-${i}`} value={simplified}>{simplified.name}</MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          }
                        </TableCell>
                        <TableCell align="center">
                          <FormControl margin="dense" variant="outlined">
                            <Select
                              value={p.quantity}
                              onChange={async (e) => {
                                setLoading(true);
                                const productsCopy = [ ...editSubscription.products ];
                                productsCopy[i] = {
                                  ...productsCopy[i],
                                  quantity: e.target.value,
                                };

                                const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                                  return acc += (+curr.price * curr.quantity);
                                }, 0);

                                let shippingAmount = editSubscription.shippingAmount;

                                if (!shippingConfig.zones || !editSubscription.shipping.state) {
                                  shippingAmount = editSubscription.shippingAmount;
                                } else {
                                  const zones = shippingConfig.zones;

                                  zones.forEach(zone => {
                                    const regions = zone.regions;

                                    for (let key in regions) {
                                      const states = regions[key].states;

                                      for (let state in states) {
                                        if (state === editSubscription.shipping.state) {
                                          shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                                        }
                                      }
                                    }
                                  });
                                }

                                let taxAmount = editSubscription.taxAmount;

                                try {
                                  const data = editSubscription;
                                  const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                                    country: data.shipping.country,
                                    state: data.shipping.state,
                                    zip: data.shipping.zip,
                                    city: data.shipping.city,
                                    street: data.shipping.street,
                                    amount: +(calculatedSubtotal.toFixed(2)),
                                    shipping: +shippingAmount,
                                    isDigital: false,
                                  });

                                  if (result.data.tax && result.data.tax.amount_to_collect) {
                                    taxAmount = result.data.tax.amount_to_collect;
                                  }
                                } catch (e) {
                                  console.log('tax calculation error', e);
                                }

                                setEditSubscription({
                                  ...editSubscription,
                                  products: productsCopy,
                                  subtotal: +(calculatedSubtotal.toFixed(2)),
                                  shippingAmount: +shippingAmount,
                                  taxAmount: +((+taxAmount).toFixed(2)),
                                  total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
                                });
                                setLoading(false);
                              }}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                            </Select>
                          </FormControl>

                          {editSubscription.products.length <= 1 ? null :
                            <IconButton
                              style={{marginLeft: 10, color: '#000'}}
                              color="default"
                              aria-label="edit"
                              onClick={async () => {
                                setLoading(true);
                                const productsCopy = [ ...editSubscription.products ];
                                productsCopy.splice(i, 1);

                                const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                                  return acc += (+curr.price * curr.quantity);
                                }, 0);

                                let shippingAmount = editSubscription.shippingAmount;

                                if (!shippingConfig.zones || !editSubscription.shipping.state) {
                                  shippingAmount = editSubscription.shippingAmount;
                                } else {
                                  const zones = shippingConfig.zones;

                                  zones.forEach(zone => {
                                    const regions = zone.regions;

                                    for (let key in regions) {
                                      const states = regions[key].states;

                                      for (let state in states) {
                                        if (state === editSubscription.shipping.state) {
                                          shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                                        }
                                      }
                                    }
                                  });
                                }

                                let taxAmount = editSubscription.taxAmount;

                                try {
                                  const data = editSubscription;
                                  const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                                    country: data.shipping.country,
                                    state: data.shipping.state,
                                    zip: data.shipping.zip,
                                    city: data.shipping.city,
                                    street: data.shipping.street,
                                    amount: +(calculatedSubtotal.toFixed(2)),
                                    shipping: +shippingAmount,
                                    isDigital: false,
                                  });

                                  if (result.data.tax && result.data.tax.amount_to_collect) {
                                    taxAmount = result.data.tax.amount_to_collect;
                                  }
                                } catch (e) {
                                  console.log('tax calculation error', e);
                                }

                                setEditSubscription({
                                  ...editSubscription,
                                  products: productsCopy,
                                  subtotal: +(calculatedSubtotal.toFixed(2)),
                                  shippingAmount: +shippingAmount,
                                  taxAmount: +((+taxAmount).toFixed(2)),
                                  total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
                                });
                                setLoading(false);
                              }}
                            >
                              <DeleteIcon style={{cursor: 'pointer'}} />
                            </IconButton>
                          }
                        </TableCell>
                        <TableCell align="right">
                          ${p.price}

                          <IconButton
                            style={{marginLeft: 10, color: '#000'}}
                            color="default"
                            aria-label="edit"
                            onClick={() => {
                              setEditPrice(p.price);
                              setEditPriceIndex(i);
                            }}
                          >
                            <EditIcon style={{fontSize: 14, cursor: 'pointer'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        {subscription.isSubscriptionBox ? null :
                          <Button variant="contained" onClick={async () => {
                            setLoading(true);
                            const productsCopy = [
                              ...editSubscription.products,
                              {
                                editableProduct: true,
                                selectedProduct: products[0],
                                isDigital: products[0].isDigital,
                                name: products[0].name,
                                price: ((+products[0].price) - (+products[0].price * .15)).toFixed(2),
                                productId: products[0].id,
                                shippingClass: products[0].shippingClass,
                                sku: products[0].sku,
                                quantity: 1,
                              },
                            ];

                            const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                              return acc += (+curr.price * curr.quantity);
                            }, 0);

                            let shippingAmount = editSubscription.shippingAmount;

                            if (!shippingConfig.zones || !editSubscription.shipping.state) {
                              shippingAmount = editSubscription.shippingAmount;
                            } else {
                              const zones = shippingConfig.zones;

                              zones.forEach(zone => {
                                const regions = zone.regions;

                                for (let key in regions) {
                                  const states = regions[key].states;

                                  for (let state in states) {
                                    if (state === editSubscription.shipping.state) {
                                      shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                                    }
                                  }
                                }
                              });
                            }

                            let taxAmount = editSubscription.taxAmount;

                            try {
                              const data = editSubscription;
                              const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                                country: data.shipping.country,
                                state: data.shipping.state,
                                zip: data.shipping.zip,
                                city: data.shipping.city,
                                street: data.shipping.street,
                                amount: +(calculatedSubtotal.toFixed(2)),
                                shipping: +shippingAmount,
                                isDigital: false,
                              });

                              if (result.data.tax && result.data.tax.amount_to_collect) {
                                taxAmount = result.data.tax.amount_to_collect;
                              }
                            } catch (e) {
                              console.log('tax calculation error', e);
                            }

                            setEditSubscription({
                              ...editSubscription,
                              products: productsCopy,
                              subtotal: +(calculatedSubtotal.toFixed(2)),
                              shippingAmount: +shippingAmount,
                              taxAmount: +((+taxAmount).toFixed(2)),
                              total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
                            });
                            setLoading(false);
                          }} size="small" color="primary">
                            Add Product
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="right">
                        <div className="totals-inner">
                          <div className="total-line">
                            <p>Subtotal:</p>
                            <p>${editSubscription.subtotal.toFixed(2)}</p>
                          </div>
                          <div className="total-line">
                            <p>Shipping:</p>
                            <p>${editSubscription.shippingAmount.toFixed(2)}</p>
                          </div>
                          <div className="total-line">
                            <p>Tax:</p>
                            <p>${editSubscription.taxAmount.toFixed(2)}</p>
                          </div>
                          <div className="total-line grand-total">
                            <p>Total:</p>
                            <p>${editSubscription.total.toFixed(2)}</p>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitUpdateSubscriptionQuantities} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditQuantitiesModalOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editPriceIndex !== -1} onClose={() => { setEditPriceIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Update Product Price</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={editPrice}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');
              setEditPrice(value);
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={async () => {
              setLoading(true);
              const productsCopy = [ ...editSubscription.products ];
              productsCopy[editPriceIndex] = {
                ...productsCopy[editPriceIndex],
                price: (+editPrice).toFixed(2),
              };

              const calculatedSubtotal = productsCopy.reduce((acc, curr) => {
                return acc += (+curr.price * curr.quantity);
              }, 0);

              let shippingAmount = editSubscription.shippingAmount;

              if (!shippingConfig.zones || !editSubscription.shipping.state) {
                shippingAmount = editSubscription.shippingAmount;
              } else {
                const zones = shippingConfig.zones;

                zones.forEach(zone => {
                  const regions = zone.regions;

                  for (let key in regions) {
                    const states = regions[key].states;

                    for (let state in states) {
                      if (state === editSubscription.shipping.state) {
                        shippingAmount = calculateUpdatedShipping(productsCopy, zone).toFixed(2);
                      }
                    }
                  }
                });
              }

              let taxAmount = editSubscription.taxAmount;

              try {
                const data = editSubscription;
                const result = await axios.post(`${config.functionsCDN}/webApi/calculate-sales-tax`, {
                  country: data.shipping.country,
                  state: data.shipping.state,
                  zip: data.shipping.zip,
                  city: data.shipping.city,
                  street: data.shipping.street,
                  amount: +(calculatedSubtotal.toFixed(2)),
                  shipping: +shippingAmount,
                  isDigital: false,
                });

                if (result.data.tax && result.data.tax.amount_to_collect) {
                  taxAmount = result.data.tax.amount_to_collect;
                }
              } catch (e) {
                console.log('tax calculation error', e);
              }

              setEditSubscription({
                ...editSubscription,
                products: productsCopy,
                subtotal: +(calculatedSubtotal.toFixed(2)),
                shippingAmount: +shippingAmount,
                taxAmount: +((+taxAmount).toFixed(2)),
                total: +(+(calculatedSubtotal.toFixed(2)) + (+shippingAmount) + (+((+taxAmount).toFixed(2)))).toFixed(2),
              });
              setLoading(false);
              setEditPriceIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setEditPriceIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!editAddressType} onClose={() => { setEditAddressType('') }} TransitionComponent={Transition}>
        <DialogTitle>Edit {editAddressType} address</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={editAddress.firstName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                firstName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Last Name"
            value={editAddress.lastName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                lastName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Primary Address"
            value={editAddress.address}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                address: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Secondary Address"
            value={editAddress.addressSecondary}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                addressSecondary: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="City"
            value={editAddress.city}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                city: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Zip"
            value={editAddress.zip}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                zip: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />

          <FormControl margin="dense" variant="outlined" style={{width: '100%', marginTop: 15}}>
            <InputLabel>State</InputLabel>
            <Select
              label="State"
              value={editAddress.state}
              onChange={(e) => {
                setEditAddress({
                  ...editAddress,
                  state: e.target.value,
                });
              }}
            >
              {states.map((s, i) => {
                return <MenuItem key={`state-${i}`} value={s.value}>{s.name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl margin="dense" variant="outlined" style={{width: '100%', marginTop: 15}}>
            <InputLabel>Country</InputLabel>
            <Select
              label="Country"
              value={editAddress.country}
              onChange={handleCountryChange}
            >
              {countries.map((c, i) => {
                return <MenuItem key={`state-${i}`} value={c.value}>{c.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {(editAddressType === 'billing' || subscription.shipping.state === editAddress.state) ? null :
            <>
              {((Math.round((+subscription.shippingAmount + Number.EPSILON) * 100) / 100) === (Math.round((+updatedShippingCost + Number.EPSILON) * 100) / 100)) ? null :
                <div style={{color: 'red'}}>
                  Notice: This change will cause the shipping cost for this subscription to update from <strong>${(Math.round((+subscription.shippingAmount + Number.EPSILON) * 100) / 100)}</strong> to <strong>${(Math.round((+updatedShippingCost + Number.EPSILON) * 100) / 100)}.</strong>
                </div>
              }

              {((Math.round((+subscription.taxAmount + Number.EPSILON) * 100) / 100) === (Math.round((+updatedTaxCost + Number.EPSILON) * 100) / 100)) ? null :
                <div style={{color: 'red'}}>
                  Notice: This change will cause the tax amount for this subscription to update from <strong>${(Math.round((+subscription.taxAmount + Number.EPSILON) * 100) / 100)}</strong> to <strong>${(Math.round((+updatedTaxCost + Number.EPSILON) * 100) / 100)}.</strong>
                </div>
              }
            </>
          }
          <Button disabled={taxLoading || !editAddress.firstName || !editAddress.lastName || !editAddress.address || !editAddress.city || !editAddress.zip} variant="contained" onClick={submitEditAddress} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditAddressType('') }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showFrequencyModal} onClose={() => { setShowFrequencyModal(false) }} TransitionComponent={Transition}>
        <DialogTitle>Update Frequency</DialogTitle>
        <DialogContent>
          <div>Ship Every</div>

          <FormControl margin="dense" variant="outlined">
            <Select
              value={updatedInterval}
              onChange={(e) => {
                setUpdatedInterval(+e.target.value)
              }}
            >
              {intervals.map((interval) => {
                return <MenuItem key={`interval-${interval}`} value={interval}>{interval}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl margin="dense" variant="outlined">
            <Select
              value={updatedPeriod}
              onChange={(e) => {
                setUpdatedPeriod(e.target.value)
              }}
            >
              {periods.map((period) => {
                return <MenuItem key={`period-${period}`} value={period}>{period}s</MenuItem>;
              })}
            </Select>
          </FormControl>

          <div style={{marginTop: 15}}>Start Updated Frequency</div>

          <FormControl margin="dense" variant="outlined">
            <Select
              value={whenToUpdateFrequency}
              onChange={(e) => {
                setWhenToUpdateFrequency(e.target.value)
              }}
            >
              <MenuItem value={'now'}>Now</MenuItem>
              <MenuItem value={'next'}>After Next Renewal</MenuItem>
            </Select>
          </FormControl>

          <Typography>
            {whenToUpdateFrequency === 'now' ? 'Frequency will update and ship immediately.' : 'Frequency will update after next scheduled renewal.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { updateFrequency() }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setShowFrequencyModal(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSkipRenewal} onClose={() => { setShowSkipRenewal(false) }} TransitionComponent={Transition}>
        <DialogTitle>Skip Next Renewal?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you would like to skip the next payment for this subscription?
          </Typography>
          <Typography>
            If you skip the next payment, the renewal date for this subscription will be updated to <strong>{moment(subscription.nextPaymentDate).add(subscription.interval, `${subscription.period}s`).format('MMM Do, YYYY')}.</strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { skipRenewal() }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setShowSkipRenewal(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showChangeRenewalDate} onClose={() => { setShowChangeRenewalDate(false) }} TransitionComponent={Transition}>
        <DialogTitle>Change Renewal Date</DialogTitle>
        <DialogContent>
          <Calendar
            onChange={setUpdatedNextRenewalDate}
            value={updatedNextRenewalDate}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={updateNextRenewalDate} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setShowChangeRenewalDate(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showShipNowModal} onClose={() => { setShowShipNowModal(false) }} TransitionComponent={Transition}>
        <DialogTitle>Update Next Renewal?</DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to update the next renewal date of this subscription or keep it the same?
          </Typography>
          <Typography>
            The next scheduled renewal date is <strong>{moment(subscription.nextPaymentDate).format('MMM Do, YYYY')}</strong>. If you choose to update the renewal date, the next renewal date will be on <strong>{moment().add(subscription.interval, `${subscription.period}s`).format('MMM Do, YYYY')}</strong>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { shipNow(true) }} color="primary">
            Update
          </Button>
          <Button variant="contained" onClick={() => { shipNow(false) }} color="primary">
            Keep
          </Button>
          <Button variant="contained" onClick={() => { setShowShipNowModal(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={reactivateSubscriptionOpen} onClose={closeSubscriptionReactivateModel} TransitionComponent={Transition}>
        <DialogTitle>Reactivate Subscription</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to reactivate this subscription?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmReactivateSubscription} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={closeSubscriptionReactivateModel} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelSubscriptionOpen} onClose={closeSubscriptionCancelModel} TransitionComponent={Transition}>
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this subscription?{!membershipsToCancel.length ? '' : ` There are ${membershipsToCancel.length} memberships associated with this subscription that will end on ${moment(subscription.nextPaymentDate).format('MMM Do, YYYY')} if you cancel this subscription.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmCancelSubscription} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={closeSubscriptionCancelModel} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Subscription;
